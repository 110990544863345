import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {
  visible: boolean = false;
  quickReload: boolean = false;

  showError(quickReload: boolean = false): void {
    this.visible = true;
    this.quickReload = quickReload;
  }
}
